import * as React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from "../components/seo"
import moment from "moment"

const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes;

  // @todo: ブログが15件を超える前にページネーションを実装する
  return (
    <Layout>
    <Seo
      title="ブログ一覧"
      description="株式会社DanRanの公式企業サイトです。「こどもたちが自由に人生を歩む社会をつくる」をミッションに、幼児向けアプリ等の提供しています。ニュース、会社概要、採用情報、お問い合わせなどを掲載しています。"
    />
      <section className="container article-list-container">
        <h1 itemprop="headline">Blog</h1>

        {/* @todo: ブログが少したまったらブログ一覧のデザインを作成する */}
        <ul className="news-list">
          {
            posts.map(node => {
              const publishedDate = moment(node.frontmatter.date);
              const link = `/blog${node.fields.slug}` 
              
              return (
                <li className="news-list__item" key={node.fields.slug}>
                  <article
                    itemScope
                    itemType="http://schema.org/Article">
                    
                    <Link to={link}>
                      <div class="news-date">
                        <span class="posted-on">
                          <time class="entry-date published updated" datetime={publishedDate.format("YYYY-MM-DD")} >
                            {publishedDate.format("YYYY.MM.DD")}
                          </time>
                        </span>
                      </div>
                      <h2 class="text">{node.frontmatter.title}</h2>
                    </Link>
                  </article>
              </li>
              )
            })
          }
        </ul>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}
      limit: 15
      filter: {fields: {sourceInstanceName: {eq: "blog"}}}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date
          title
        }
      }
    }
  }
`;

export default BlogIndex;
